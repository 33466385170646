import { Grid, Icon, LinearProgress, Paper } from '@material-ui/core';

import { Avatar, Typography } from '@mui/material';
import * as React from 'react';
import { useChartStyles } from '../../../_styles/chartStyles';

export default function ReportChartUserInfo(params: { icon: string, backgroundColor: string, iconColor: string, title: string, secondaryTitle: string, isLoading?: boolean, isNumeric?: boolean }) {

    const classes = useChartStyles();

    return (
        <Paper className={classes.reportChartPaper} style={{ backgroundColor: params.backgroundColor }}>
            <Grid container>
                <Grid item xs={4}>
                    <Avatar className={classes.reportChartAvatar} style={{ backgroundColor: params.iconColor }}>
                        <Icon>{params.icon}</Icon>
                    </Avatar>
                </Grid>
                <Grid item xs={8} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs className={classes.description}>
                            <Typography gutterBottom variant="subtitle1">
                                {params.title}
                            </Typography>

                            <Typography variant="body2" color="textSecondary">
                                {params.isLoading && <LinearProgress />}
                                {!params.isLoading && params.secondaryTitle}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>

    );
}

