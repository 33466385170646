import * as React from 'react';
import { ITableData } from '../../_common/interface';
import { useEffect, useState } from 'react';
import { ReportViewModel } from '../../_common/models/report/reportView.model';
import { toastr } from '../../_components/toastr/toastr';
import { reportService } from '../../_services/report.service';
import { Box, Container, Divider, LinearProgress, ListItem, Paper, Toolbar, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useReportViewStyles } from '../../_styles/reportView.styles';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';


export function ReportOverweightView(params: { id: number }) {

    const [isLoading, setLoading] = useState(false);
    const [reportData, setReportData] = useState<Partial<ReportViewModel>>(resetReportData);
    const [overweightWasteDataList, setOverweightWasteDataList] = useState<ITableData>({ items: [], count: 0 })

    const { id } = useParams<{ id: any }>();
    const classes = useReportViewStyles();

    useEffect(() => {
        if (id)
            fetchData();
        fetchOverweightWasteData();
    }, [id]);

    function resetReportData() {
        return {
            id: 0,
            acceptanceDate: "",
            customerName: "",
            invoice: "",
            lorryreg: "",
            location: "",
            wtf: ""
        };
    }

    // Report data
    function fetchData() {

        if (!id) return;

        setLoading(true);

        return reportService
            .get(id)
            .then(
                (json) => {
                    console.log("Get Data:", json);
                    setReportData(json);
                },

                (e) => {
                    toastr.error("Error getting report data");
                }
            )
            .finally(() => setLoading(false));
    }

    // Overweight wastes data
    function fetchOverweightWasteData() {

        if (!id) return;

        setLoading(true);

        return reportService
            .getOverweightWastes(id)
            .then(data => {
                data.items = data.items || [];
                setOverweightWasteDataList(data);
                console.log("Overweight waste data list:", data);
            },
                (e) => { console.log(e); toastr.error("Something went wrong. Please try again later") })
            .finally(() => setLoading(false));
    }


    return (
        <>
            <br />

            <Paper>
                {isLoading && <LinearProgress />}

                <div className="heading-h6">Overweight Job #{reportData.id}</div>

                <Grid container spacing={1} className="report-container">

                    <Grid container md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={4} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography><span className="item-title">Company Name: </span></ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item md={8} lg={8} sm={8} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography key={reportData.customerName}>{reportData.customerName}</ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>

                    <Divider style={{ width: '98%', margin: '0 auto' }} />


                    <Grid container md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={4} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography><span className="item-title">Invoice: </span></ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item md={8} lg={8} sm={8} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography key={reportData.invoice}>{reportData.invoice}</ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>

                    <Divider style={{ width: '98%', margin: '0 auto', borderBottomWidth: 3 }} />

                    {(overweightWasteDataList && overweightWasteDataList.items.length > 0) && overweightWasteDataList.items.map((wasteData, index) =>
                        <>
                            <Typography variant="h6" component="h6" className={classes.title}>Waste #{wasteData.id}</Typography>

                            <Grid container md={12} lg={12} sm={12} xs={12}>
                                <Grid item md={4} lg={4} sm={4} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography><span className="item-title">Allowed Container Weight: </span></ListItemText>
                                    </ListItem>
                                </Grid>
                                <Grid item md={8} lg={8} sm={8} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography key={wasteData.maxWeight}>{wasteData.maxWeight}kg</ListItemText>
                                    </ListItem>
                                </Grid>
                            </Grid>

                            <Divider style={{ width: '98%', margin: '0 auto' }} />

                            <Grid container md={12} lg={12} sm={12} xs={12}>
                                <Grid item md={4} lg={4} sm={4} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography><span className="item-title">Actual Average Container Weight: </span></ListItemText>
                                    </ListItem>
                                </Grid>
                                <Grid item md={8} lg={8} sm={8} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography key={wasteData.weight / wasteData.number}>{(wasteData.weight / wasteData.number).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}kg</ListItemText>
                                    </ListItem>
                                </Grid>
                            </Grid>

                            <Divider style={{ width: '98%', margin: '0 auto' }} />

                            <Grid container md={12} lg={12} sm={12} xs={12}>
                                <Grid item md={4} lg={4} sm={4} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography><span className="item-title">Excess Container Weight: </span></ListItemText>
                                    </ListItem>
                                </Grid>
                                <Grid item md={8} lg={8} sm={8} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography key={(wasteData.weight / wasteData.number) - wasteData.maxWeight}>{((wasteData.weight / wasteData.number) - wasteData.maxWeight).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}kg</ListItemText>
                                    </ListItem>
                                </Grid>
                            </Grid>

                            <Divider style={{ width: '98%', margin: '0 auto' }} />

                            <Grid container md={12} lg={12} sm={12} xs={12}>
                                <Grid item md={4} lg={4} sm={4} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography
                                            style={{ color: 'red' }} ><span className="">Excess Weight Total: </span></ListItemText>
                                    </ListItem>
                                </Grid>
                                <Grid item md={8} lg={8} sm={8} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography
                                            style={{ color: 'red' }} key={((wasteData.weight / wasteData.number) - wasteData.maxWeight) * wasteData.number}>{(((wasteData.weight / wasteData.number) - wasteData.maxWeight) * wasteData.number).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}kg</ListItemText>
                                    </ListItem>
                                </Grid>
                            </Grid>

                            <Divider style={{ width: '98%', margin: '0 auto', borderBottomWidth: 3 }} />

                            <Typography variant="h6" component="h6" className={classes.subtitle}>Waste #{wasteData.id} Details</Typography>

                            <Grid container md={12} lg={12} sm={12} xs={12}>
                                <Grid item md={4} lg={4} sm={4} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography><span className="item-title">Proper Shipping Name: </span></ListItemText>
                                    </ListItem>
                                </Grid>
                                <Grid item md={8} lg={8} sm={6} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography key={wasteData.properShippingName}>{wasteData.properShippingName}</ListItemText>
                                    </ListItem>
                                </Grid>
                            </Grid>

                            <Divider style={{ width: '98%', margin: '0 auto' }} />

                            <Grid container md={12} lg={12} sm={12} xs={12}>
                                <Grid item md={4} lg={4} sm={4} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography><span className="item-title">UN Code: </span></ListItemText>
                                    </ListItem>
                                </Grid>
                                <Grid item md={8} lg={8} sm={8} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography key={wasteData.unCodeName}>{wasteData.unCodeName}</ListItemText>
                                    </ListItem>
                                </Grid>
                            </Grid>

                            <Divider style={{ width: '98%', margin: '0 auto' }} />

                            <Grid container md={12} lg={12} sm={12} xs={12}>
                                <Grid item md={4} lg={4} sm={4} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography><span className="item-title">EWC: </span></ListItemText>
                                    </ListItem>
                                </Grid>
                                <Grid item md={8} lg={8} sm={8} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography key={wasteData.ewc}>{wasteData.ewc}</ListItemText>
                                    </ListItem>                        </Grid>
                            </Grid>

                            <Divider style={{ width: '98%', margin: '0 auto' }} />

                            <Grid container md={12} lg={12} sm={12} xs={12}>
                                <Grid item md={4} lg={4} sm={4} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography><span className="item-title">Shipping Class: </span></ListItemText>
                                    </ListItem>
                                </Grid>
                                <Grid item md={8} lg={8} sm={8} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography key={wasteData.shippingClassName}>{wasteData.shippingClassName}</ListItemText>
                                    </ListItem>
                                </Grid>
                            </Grid>

                            <Divider style={{ width: '98%', margin: '0 auto' }} />

                            <Grid container md={12} lg={12} sm={12} xs={12}>
                                <Grid item md={4} lg={4} sm={4} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography><span className="item-title">Packing Group: </span></ListItemText>
                                    </ListItem>
                                </Grid>
                                <Grid item md={8} lg={8} sm={8} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography key={wasteData.packingGroupName}>{wasteData.packingGroupName}</ListItemText>
                                    </ListItem>
                                </Grid>
                            </Grid>

                            <Divider style={{ width: '98%', margin: '0 auto' }} />

                            <Grid container md={12} lg={12} sm={12} xs={12}>
                                <Grid item md={4} lg={4} sm={4} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography><span className="item-title">Tunnel Code: </span></ListItemText>
                                    </ListItem>
                                </Grid>
                                <Grid item md={8} lg={8} sm={8} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography key={wasteData.tunnelCodeName}>{wasteData.tunnelCodeName}</ListItemText>
                                    </ListItem>
                                </Grid>
                            </Grid>

                            <Divider style={{ width: '98%', margin: '0 auto' }} />

                            <Grid container md={12} lg={12} sm={12} xs={12}>
                                <Grid item md={4} lg={4} sm={4} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography><span className="item-title">Type: </span></ListItemText>
                                    </ListItem>
                                </Grid>
                                <Grid item md={8} lg={8} sm={8} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography key={wasteData.typeOf}>{wasteData.typeOf}</ListItemText>
                                    </ListItem>
                                </Grid>
                            </Grid>

                            <Divider style={{ width: '98%', margin: '0 auto' }} />

                            <Grid container md={12} lg={12} sm={12} xs={12}>
                                <Grid item md={4} lg={4} sm={4} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography><span className="item-title">Container: </span></ListItemText>
                                    </ListItem>
                                </Grid>
                                <Grid item md={8} lg={8} sm={8} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography key={wasteData.container}>{wasteData.container}</ListItemText>
                                    </ListItem>
                                </Grid>
                            </Grid>



                            <Divider style={{ width: '98%', margin: '0 auto' }} />

                            <Grid container md={12} lg={12} sm={12} xs={12}>
                                <Grid item md={4} lg={4} sm={4} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography><span className="item-title">Number: </span></ListItemText>
                                    </ListItem>
                                </Grid>
                                <Grid item md={8} lg={8} sm={8} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography key={wasteData.number}>{wasteData.number}</ListItemText>
                                    </ListItem>
                                </Grid>
                            </Grid>

                            <Divider style={{ width: '98%', margin: '0 auto' }} />

                            <Grid container md={12} lg={12} sm={12} xs={12}>
                                <Grid item md={4} lg={4} sm={4} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography><span className="item-title">Weight: </span></ListItemText>
                                    </ListItem>
                                </Grid>
                                <Grid item md={8} lg={8} sm={8} xs={6}>
                                    <ListItem>
                                        <ListItemText disableTypography key={wasteData.weight}>{wasteData.weight}</ListItemText>
                                    </ListItem>
                                </Grid>
                            </Grid>
                            <Divider style={{ width: '99.9%', margin: '0 auto', borderBottomWidth: 6 }} />

                        </>
                    )}
                </Grid>
            </Paper>
        </>
    );

}
