import { Box, Container, Grid, Icon, MenuItem, Tab, Tabs, TextField } from '@material-ui/core';
import { useState } from 'react';
import { SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { IDropdownData } from '../../_common/interface';
import { CompanyAutoComplete } from '../../_components/autoComplete/companies/company.autocomplete';
import { getDateString } from '../../_helpers';
import { accountService } from '../../_services/account.service';
import { useChartStyles } from '../../_styles/chartStyles';
import ReportChartHorizontal from '../report/components/report-chart_horizontal';
import ReportChartUserHorizontal from '../report/components/report-chart-users_horizontal';
import { PrimaryButton } from '../../_components/buttons/primary.button';


export function DashboardIndex() {

    const [startYear, setStartyear] = useState(null);
    const [month, setMonth] = useState(null);
    const [quarter, setQuarter] = useState(null);

    const [search, setSearch] = useState({ timePeriod: "", companyId: null, customerIds: "", startDate: "", endDate: "" });
    const [actualSearch, setActualSearch] = useState({ timePeriod: "", companyId: null, customerIds: "", startDate: "", endDate: "" });
    const classes = useChartStyles();

    const today = new Date();
    const years = [...Array(new Date().getFullYear() - 2017)];
    const months = [...Array(12)];
    const quarters = ["Q1", "Q2", "Q3", "Q4"];

    function setValue(event: any) {
        setSearch(_search => { return { ..._search, [event.target.name]: event.target.value } })
    }

    function handleYearDropdown(value: any) {
        setStartyear(value);

        setSearch(_search => {

            let startMonth = _search.startDate ? new Date(_search.startDate).getMonth() : 0;
            let endMonth = _search.endDate ? new Date(_search.endDate).getMonth() : 11;
            let endDate = new Date(value, endMonth + 1, 0).getDate();

            return {
                ..._search,
                startDate: getDateString(new Date(value, startMonth, 1)),
                endDate: getDateString(new Date(value, endMonth, endDate))
            }
        });
    };

    function handleQuarterDropdown(value: any) {
        setQuarter(value);

        setSearch(_search => {
            let startMonth, endMonth;

            let startYear = _search.startDate ? new Date(_search.startDate).getFullYear() : today.getFullYear();
            let endYear = _search.endDate ? new Date(_search.endDate).getFullYear() : today.getFullYear();

            switch (value) {
                case "Q1": startMonth = 0; endMonth = 2; break;
                case "Q2": startMonth = 3; endMonth = 5; break;
                case "Q3": startMonth = 6; endMonth = 8; break;
                case "Q4": startMonth = 9; endMonth = 11; break;
            }

            let endDate = new Date(endYear, endMonth + 1, 0).getDate();
            return {
                ..._search,
                startDate: getDateString(new Date(startYear, startMonth, 1)),
                endDate: getDateString(new Date(endYear, endMonth, endDate))
            }
        });
        console.log("search", search);
    };

    function handleMonthDropdown(value: any) {
        setMonth(value);
        setSearch(_search => {

            let startYear = _search.startDate ? new Date(_search.startDate).getFullYear() : today.getFullYear();
            let endYear = _search.endDate ? new Date(_search.endDate).getFullYear() : today.getFullYear();
            let endDate = new Date(endYear, value + 1, 0).getDate();

            return {
                ..._search,
                startDate: getDateString(new Date(startYear, value, 1)),
                endDate: getDateString(new Date(endYear, value, endDate))
            }
        });
        console.log("search", search);
    };

    function selectCompanies(companies: Array<any>) {

        setSearch(_data => {
            let companyIds = companies && companies.length ? companies.map(m => m.id) : [];
            var companyIdsJoined = companyIds.join(",");
            return { ..._data, customerIds: companyIdsJoined }
        })
    }

    return (
        <Container component="main">

            <h2 className="report-view-title">Dashboard</h2>
            <ValidatorForm onSubmit={() => { }} noValidate>

                <Grid container
                    alignItems="center"
                    justifyContent="center">
                    {(accountService.isAdmin || !accountService.isAdmin && accountService.companiesCount > 1) &&
                        <Grid item sm={2} xs={6}>
                            <CompanyAutoComplete
                                name={"companyId"}
                                onSelect={selectCompanies}
                                itemId={search.customerIds}
                                hasParent={false}
                                variant="standard"
                                isRequired
                            />
                        </Grid>
                    }
                    <Grid item sm={2} xs={6}>

                        <SelectValidator
                            value={month}
                            onChange={(event) => handleMonthDropdown((event.target as any).value)}
                            label="Select Month"
                            name="month"
                            fullWidth
                            variant="standard">
                            {
                                months.map((month: number, index: number) => {
                                    return <MenuItem key={`month${index}`} value={index}>{new Date(today.setMonth(index)).toLocaleString('default', { month: 'long' })}</MenuItem>
                                })
                            }
                        </SelectValidator>
                    </Grid>

                    <Grid item sm={2} xs={6}>

                        <SelectValidator
                            value={quarter}
                            onChange={(event) => handleQuarterDropdown((event.target as any).value)}
                            label="Select Quarter"
                            name="quarter"
                            fullWidth
                            variant="standard">
                            {
                                quarters.map((quarter: string, index: number) => {
                                    return <MenuItem key={`quarter${index}`} value={quarter}>{quarter}</MenuItem>
                                })
                            }
                        </SelectValidator>
                    </Grid>

                    <Grid item sm={2} xs={6}>

                        <SelectValidator
                            value={startYear}
                            onChange={(event) => handleYearDropdown((event.target as any).value)}
                            label="Select Year"
                            name="year"
                            fullWidth
                            variant="standard">
                            {
                                years.map((year: number, index: number) => {
                                    return <MenuItem key={`year${index}`} value={new Date().getFullYear() - index}>{new Date().getFullYear() - index}</MenuItem>
                                })
                            }
                        </SelectValidator>

                    </Grid>
                    <Grid item sm={2} xs={6}>
                        <TextField
                            id="date"
                            label="Start Date"
                            type="date"
                            name="startDate"
                            value={search.startDate}
                            onChange={setValue}
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                    </Grid>
                    <Grid item sm={2} xs={6}>
                        <TextField
                            id="date"
                            label="End Date"
                            type="date"
                            name="endDate"
                            onChange={setValue}
                            value={search.endDate}
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container
                    alignItems="center"
                    justifyContent="center">

                    <Grid item md={4} xs={12}>
                        <PrimaryButton
                            type="submit"
                            variant="success"
                            onClick={() => { setActualSearch({ ...search }) }}>
                            <Icon>search</Icon> Search
                        </PrimaryButton>
                    </Grid>
                </Grid>

            </ValidatorForm>

            <div className={classes.dashboardMain}>
                {accountService.isAdmin &&
                    <ReportChartHorizontal search={actualSearch} />
                }
                {!accountService.isAdmin &&
                    <ReportChartUserHorizontal search={actualSearch} />
                }
            </div>

        </Container >
    );
}