import * as React from 'react';
import { useEffect, useState } from 'react';
import { ReportViewModel } from '../../_common/models/report/reportView.model';
import { WasteView } from '../../_common/models/waste/waste.model';
import { toastr } from '../../_components/toastr/toastr';
import { reportService } from '../../_services/report.service';
import { Box, Container, Divider, LinearProgress, ListItem, Paper, Toolbar, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useReportViewStyles } from '../../_styles/reportView.styles';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';

export function ReportSurchargeView(params: { id: number }) {

    const [isLoading, setLoading] = useState(false);
    const [reportData, setReportData] = useState<Partial<ReportViewModel>>(resetReportData);
    const [wasteData, setWasteData] = useState<Partial<WasteView>>(resetWasteData);

    const { id } = useParams<{ id: any }>();
    const classes = useReportViewStyles();

    useEffect(() => {
        if (id)
            fetchData();
        fetchSurchargeWasteData();
    }, [id]);


    function resetReportData() {
        return {
            id: 0,
            customerName: "",
            invoice: "",
        };
    }

    function resetWasteData() {
        return {
            shippingIssueName: "",
            shippingIssuActionName: "",
            shippingIssueMaterialsName: "",
            manHours: 0,
            note: "",
            imageUrl: ""
        };
    }

    function fetchData() {

        if (!id) return;

        setLoading(true);

        return reportService
            .get(id)
            .then(
                (json) => {
                    console.log("Get Data:", json);
                    setReportData(json);
                },

                (e) => {
                    toastr.error("Error getting report data");
                }
            )
            .finally(() => setLoading(false));
    }

    function fetchSurchargeWasteData() {

        if (!id) return;

        setLoading(true);

        return reportService
            .getSurchargeWaste(id)
            .then(
                (json) => {
                    console.log("Get Surcharge Waste Data:", json);
                    setWasteData(json);
                },

                (e) => {
                    toastr.error("Error getting surcharge data");
                }
            )
            .finally(() => setLoading(false));
    }


    return (
        <>
            <br />

            <Paper>
                {isLoading && <LinearProgress />}

                <div className="heading-h6">Surcharge Job #{reportData.id}</div>

                <Grid container spacing={1} className="report-container">

                    <Grid container md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={4} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography><span className="item-title">Company Name: </span></ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item md={8} lg={8} sm={8} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography key={reportData.customerName}>{reportData.customerName}</ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>

                    <Divider style={{ width: '98%', margin: '0 auto' }} />


                    <Grid container md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={4} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography><span className="item-title">Invoice: </span></ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item md={8} lg={8} sm={8} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography key={reportData.invoice}>{reportData.invoice}</ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>

                    <Divider style={{ width: '98%', margin: '0 auto', borderBottomWidth: 3 }} />

                    <Typography variant="h6" component="h6" className={classes.subtitle}>Non Conformance Details</Typography>

                    <Grid container md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={4} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography><span className="item-title">Shipping Issue: </span></ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item md={8} lg={8} sm={4} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography key={wasteData.shippingIssueName}>{wasteData.shippingIssueName}</ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>

                    <Divider style={{ width: '98%', margin: '0 auto' }} />

                    <Grid container md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={4} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography><span className="item-title">Action Taken: </span></ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item md={8} lg={8} sm={8} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography key={wasteData.shippingIssueActionName}>{wasteData.shippingIssueActionName}</ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>

                    <Divider style={{ width: '98%', margin: '0 auto' }} />

                    <Grid container md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={4} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography><span className="item-title">Material Used: </span></ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item md={8} lg={8} sm={4} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography key={wasteData.shippingIssueMaterialName}>{wasteData.shippingIssueMaterialName}</ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>

                    <Divider style={{ width: '98%', margin: '0 auto' }} />

                    <Grid container md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={4} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography><span className="item-title">Man Hours: </span></ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item md={8} lg={8} sm={8} xs={6}>
                            <ListItem>
                                {!isLoading &&
                                    <ListItemText disableTypography key={wasteData.manHours}>{wasteData.manHours}</ListItemText>
                                }
                            </ListItem>
                        </Grid>
                    </Grid>

                    <Divider style={{ width: '98%', margin: '0 auto' }} />

                    <Grid container md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={4} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography><span className="item-title">Note: </span></ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item md={8} lg={8} sm={8} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography key={wasteData.note}>{wasteData.note}</ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>

                    <Divider style={{ width: '98%', margin: '0 auto' }} />

                    <Grid container md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={4} xs={2}>
                            <ListItem>
                                <ListItemText disableTypography><span className="item-title">Image: </span></ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item md={8} lg={8} sm={8} xs={10}>
                            <ListItem>
                                {!isLoading &&
                                    <ListItemText disableTypography key={wasteData.imageUrl}><img src={wasteData.imageUrl} alt="Surcharge Job Image" width="200" height="200" className="waste-image" /></ListItemText>
                                }
                            </ListItem>
                        </Grid>
                    </Grid>
                    <Divider style={{ width: '99.9%', margin: '0 auto', borderBottomWidth: 6 }} />

                </Grid>
            </Paper>
        </>
    );

}

