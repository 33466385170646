import { Container, Grid, Icon, Paper, TextField, Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useLocation } from 'react-router-dom';
import { IDropdownData } from '../../_common/interface';
import { IFilteringViewModel, ITableData } from '../../_common/interface/iTableFilter.interface';
import { CompanyAutoComplete } from '../../_components/autoComplete/companies/company.autocomplete';
import { PrimaryButton } from '../../_components/buttons/primary.button';
import ReportSplitButton from '../../_components/buttons/report.split.button';
import ProgressBarGreen from '../../_components/progressBars/progressBarGreen';
import ProgressBarRed from '../../_components/progressBars/progressBarRed';
import ProgressBarYellow from '../../_components/progressBars/progressBarYellow';
import { CommonTable } from '../../_components/table/table';
import { toastr } from '../../_components/toastr/toastr';
import { getDateStringNormalized, historyHelper } from '../../_helpers';
import { accountService } from '../../_services/account.service';
import { reportService } from '../../_services/report.service';
import { userService } from '../../_services/user.service';
import { useIndexStyles } from '../../_styles/index.styles';


export function ReportIndex() {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });

    const querySearch = useLocation().search;
    const searchParams = new URLSearchParams(querySearch);
    const wtf = searchParams.get("wtf") || null;
    const invoice = searchParams.get("invoice") || null;
    const startDate = searchParams.get("startDate") || null;
    const endDate = searchParams.get("endDate") || null;
    const companyId = searchParams.get("companyId") || null;
    const customerIds = searchParams.get("customerIds") || null;

    const [search, setSearch] = useState({
        startDate: startDate, endDate: endDate, invoice: invoice, wtf: wtf,
        companyId: null, customerIds: customerIds
    });

    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 20, page: 0, order: 'desc', orderBy: '' });
    const [companies, setCompanies] = useState<Array<IDropdownData>>([]);

    const classes = useIndexStyles();

    const columns = [
        { title: 'Creation Date', field: 'creationDate', render: (rowData: any) => <>{getDateStringNormalized(rowData.creationDate, false)}</> },
        { title: 'Acceptance Date', field: 'acceptanceDate', render: (rowData: any) => <>{getDateStringNormalized(rowData.acceptanceDate, false)}</> },
        { title: 'Customer', field: 'customerName' },
        { title: 'Invoice', field: 'invoice' },
        { title: 'WTF', field: 'wtf' },
        { title: 'Actions', fields: 'httpLink', render: (rowData: any) => <ReportSplitButton wtf={rowData.wtf} id={rowData.id} isApproved={rowData.approved} hasDate={!!rowData.acceptanceDate} /> },
        {
            title: 'Completion', field: 'completionStatus', render: (rowData: any) =>
                <>
                    {
                        (rowData.approved == true) && <ProgressBarGreen value={rowData.completionStatus} />
                    }
                    {
                        (rowData.approved == false && rowData.reportComplete == true) && <ProgressBarYellow value={rowData.completionStatus} />
                    }
                    {
                        ((rowData.approved == false && rowData.reportComplete == false)) && <ProgressBarRed value={rowData.completionStatus} />
                    }
                </>
        },
        {
            title: '', field: 'overweight', render: (rowData: any) =>
                <>
                    {(rowData.overweight == true) &&
                        <Tooltip title="Overweight">
                            <Icon style={{ color: rowData.overweightAction == 'surcharge_required' || !rowData.overweightAction ? "red" : rowData.overweightAction == 'surcharge_paid' || rowData.overweightAction == 'surcharge_not_required' ? "green" : "" }}>report</Icon>
                        </Tooltip>
                    }
                    {(rowData.hasNonConformance == true) &&
                        <Tooltip title="Surcharge">
                            <Icon style={{ color: rowData.hasSurcharge == false ? "red" : "green" }}>bookmarks</Icon>
                        </Tooltip>
                    }
                </>
        },

    ];

    useEffect(() => {
        fetchData();
    }, [filter, wtf, invoice, startDate, endDate, companyId, customerIds]);


    function fetchData() {

        setLoading(true);

        return reportService.getReports({ ...filter, wtf: wtf, invoice: invoice, startDate: startDate, endDate: endDate, companyId: companyId, customerIds: customerIds })
            .then(data => {
                console.log("report index:", data);
                data.items = data.items || [];
                setData(data);
                data.companies = data.companies || [];
                setCompanies(data.companies);
            },
                (e) => {
                    console.log(e);
                    toastr.error("Something went wrong. Please try again later")
                })
            .finally(() => setLoading(false));
    }

    function setValue(event: any) {
        setSearch(_search => { return { ..._search, [event.target.name]: event.target ? event.target.value : null } })
    }

    const renderReport = (reportType: string) => () => {
        historyHelper.push(`Report/PDF/?wtf=${search.wtf}&startDate=${search.startDate}&endDate=${search.endDate}&invoice=${search.invoice}&reportType=${reportType}&reportSource=List&companyId=${search.companyId}&customerIds=${search.customerIds}`);
    }    

    function selectCompanies(companies: Array<any>) {
        setSearch(_search => {
            let companyIds = companies && companies.length ? companies.map(m => m.id) : [];
            var companyIdsJoined = companyIds.join(",");
            return { ..._search, customerIds: companyIdsJoined }
        })
        setCompanies(companies);
    }

    return (
        <>
            <Container component="main">
                <h2 className="report-view-title">Jobs</h2>
                <Paper>
                    <div className="heading-h6">Search</div>

                    <ValidatorForm onSubmit={() => {
                        var url = `/?wtf=${search.wtf || ""}&startDate=${search.startDate || ""}&endDate=${search.endDate || ""}&invoice=${search.invoice || ""}`;
                        if (accountService.isAdmin) url += `&companyId=${search.companyId || ""}&customerIds=${search.customerIds || ""}` ;
                        historyHelper.push(url);
                    }} noValidate>

                        <Grid container>
                            {accountService.isAdmin &&
                                <Grid item sm={4} xs={12}>                                
                                    <CompanyAutoComplete
                                        name={"customerIds"}
                                        onSelect={selectCompanies}
                                        multipleValues={companies}
                                        hasParent={false}
                                        variant="filled"                                        
                                    />
                                </Grid>
                            }
                            <Grid item sm={accountService.isAdmin ? 2 : 3} xs={6}>
                                <TextField id="filled-basic" onChange={setValue} defaultValue={invoice} name="invoice" label="Invoice Number" variant="filled" value={search.invoice} />
                            </Grid>
                            <Grid item sm={accountService.isAdmin ? 2 : 3} xs={6}>
                                <TextField id="filled-basic" onChange={setValue} defaultValue={wtf} name="wtf" label="WTF Code" variant="filled" value={search.wtf} />
                            </Grid>
                            <Grid item sm={accountService.isAdmin ? 2 : 3} xs={6}>
                                <TextField
                                    id="date"
                                    label="Start Date"
                                    type="date"
                                    name="startDate"
                                    defaultValue={startDate}
                                    value={search.startDate}
                                    onChange={setValue}
                                    variant="filled"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item sm={accountService.isAdmin ? 2 : 3} xs={6}>
                                <TextField
                                    id="date"
                                    label="End Date"
                                    type="date"
                                    name="endDate"
                                    onChange={setValue}
                                    defaultValue={endDate}
                                    value={search.endDate}
                                    variant="filled"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <PrimaryButton
                                    type="submit"
                                    variant="success">
                                    <Icon>search</Icon> Search
                                </PrimaryButton>
                            </Grid>

                            <Grid item md={2} xs={4}>
                                <PrimaryButton
                                    type="button"
                                    variant="info"
                                    onClick={renderReport("Acceptance")}
                                    disabled={!search.wtf && !search.invoice && (!endDate || !startDate)}>
                                    <Icon>check</Icon>Acceptance Report
                                </PrimaryButton>
                            </Grid>

                            <Grid item md={2} xs={4}>
                                <PrimaryButton
                                    type="button"
                                    variant="danger"
                                    onClick={renderReport("Destruction")}
                                    disabled={!search.wtf && !search.invoice && (!endDate || !startDate)}>
                                    <Icon>check</Icon>Destruction Report
                                </PrimaryButton>
                            </Grid>

                            <Grid item md={2} xs={4}>
                                <PrimaryButton
                                    type="button"
                                    variant="warning"
                                    onClick={renderReport("Summary")}
                                    disabled={!search.wtf && !search.invoice && (!endDate || !startDate)}>
                                    <Icon>check</Icon>Summary Report
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    </ValidatorForm>

                    <CommonTable
                        title={null}
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        setFilter={setFilter}
                        options={[{ search: false }]}
                    />
                </Paper>
            </Container>

        </>
    );

}

