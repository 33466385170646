import { makeStyles } from '@material-ui/core/styles';

export const useBasicTableStyles = makeStyles(theme => ({
    
    tableNested: {
        border: "1px solid grey"
    },
    tableContainer: {       
        display: "flex",
        backgroundColor: "white",
        margin: "20px",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 5px",
        borderRadius: "2px",
        userSelect: 'none'
    },
    tableHead: {
        backgroundColor: "#c1e1f2 !important",
        fontWeight: "bold"
    },
    tableHeadNested: {
        backgroundColor: "lightgrey !important"
    },
    tableTitleRow: {
        height: "20px !important",
        fontWeight: "bold"
    },
    tableTitleCell: {
        
        height: "20px !important"
    },
    tableRightBorder: {
        borderWidth: .5, borderColor: '#B2BEB5', borderStyle: 'solid'
    },
    headerCell: {
        color: '#2a8bbf !important',
        fontSize: '20px !important',
        backgroundColor: 'white !important',
        borderBottom: '2px solid grey !important',
        userSelect: 'none'
    },
    subHeaderCell: {
        color: '#2a8bbf !important',
        fontSize: '18px !important',
        backgroundColor: '#fefbf9 !important',
        borderBottom: '2px solid grey !important',
        userSelect: 'none'
    }

}));