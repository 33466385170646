import { API_URL } from '../_common/const/config.const';
import { getHeader, getQuery } from '../_helpers';
import { handleFailResponse, handleResponse } from '../_helpers/handle-response';

const API_NAME = "dashboard";

export const dashboardService = {

    getReportGraphs,
    getReportChart,
    getReportChartRecoveryDisposal,
    getReportChartEwc,
    getReportChartTonnageByYear
};


function getReportGraphs(query?: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getReportGraphs/?${getQuery(query)}`, requestOptions).then(handleResponse, handleFailResponse);
}

function getReportChart(query?: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getReportChart/?${getQuery(query)}`, requestOptions).then(handleResponse, handleFailResponse);
}

function getReportChartRecoveryDisposal(query?: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getReportChartRecoveryDisposal/?${getQuery(query)}`, requestOptions).then(handleResponse, handleFailResponse);
}

function getReportChartEwc(query?: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getReportChartEwc/?${getQuery(query)}`, requestOptions).then(handleResponse, handleFailResponse);
}

function getReportChartTonnageByYear(query?: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getReportChartTonnageByYear?${getQuery(query)}`, requestOptions).then(handleResponse, handleFailResponse);
}


