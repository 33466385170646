import * as React from 'react';
import { Component, useState } from 'react';
import Chart from 'react-apexcharts';
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { toastr } from '../toastr/toastr';
import { dashboardService } from '../../_services/dashboard.service';
import { LinearProgress } from '@material-ui/core';

export default function TonnageByYearsChart(params: { search: any }) {

    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState({ year: [], tWeight: [] });

    React.useEffect(() => {
        fetchData();
    }, [params.search]);

    function fetchData() {

        setLoading(true);

        return dashboardService.getReportChartTonnageByYear(params.search)
            .then(data => {
                console.log("tonnage",data);
                setData(data);
            },
                (e) => {
                    console.log(e);
                    toastr.error("Something went wrong. Please try again later")
                })
            .finally(() => setLoading(false));
    }

    function format(num: number) {
        var n = num.toFixed(1);
        return String(n).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');
    } 

    const chartData: ApexOptions = {

        series: [{
            name: "Weight(t)",
            data: data.tWeight
        }],
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false
            },
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 330,
                    offsetY: 20,
                    offsetX: -5
                },
                legend: {
                    position: 'bottom'
                },
                title: {
                    text: 'Waste Tonnage By Years',
                    align: 'center',
                    offsetY: 0,
                    offsetX: 0
                },
            },
        }],
        title: {
            text: 'Waste Tonnage By Years',
            align: 'left',
            offsetX: 110
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: data.year,
            title: {
                text: "Weight(t)",
                style: {
                    color: 'grey',
                },
                offsetY: 10
            },
            

        },
        yaxis: {
            title: {
                text: "Years",
                style: {
                    color: 'grey',
                },
                offsetX: 10
            },
        },
        tooltip: {
            y: {
                formatter: function (value) {
                    return format(value);
                }
            },
        },
    }


    //return <>
    //    {isLoading && <LinearProgress />}
    //    <ReactApexChart options={chartData} type="bar" height={326} series={chartData.series} />
    //</>

    return <>
        {data.tWeight.length != 0 &&
            <>
                {isLoading && <LinearProgress />}
            <ReactApexChart options={chartData} type="bar" height={326} series={chartData.series} />
            </>
        }
        {data.tWeight.length == 0 &&
            <div>
                <p style={{ textAlign: 'center' }}>No waste data available.</p>
            </div>
        }
    </>
};


