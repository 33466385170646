import { makeStyles } from '@material-ui/core/styles';

export const useDrawerStyles = makeStyles(theme => ({
    root: {
        "& .MuiDrawer-paper": {
            width: "100%",
            maxWidth: "650px",
            overflowX: "hidden"
        },
        "&>.MuiPaper-root>div": {
            height: "64px"
        }
    },
    closeButton: {
        position: `absolute`,
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "white !important",
        width: "40px",
    },
    spacerBottom: {
        marginBottom: theme.spacing(.5)
    },
    alignRight: {
        marginLeft: 'auto'
    },
    redText: {
        color: "red",
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5)
    },
    tabBorderBottom: {
        borderBottom: 1,
        borderColor: 'divider'
    },
    tabPaddingRight: {
        paddingRight: "12px"
    }
}));