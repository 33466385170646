import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { BottomNavigation, BottomNavigationAction, Box, CssBaseline, Hidden, Icon, Menu, MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ADMIN_NAV, PRIMARY_NAV, THEME, USER_NAV } from '../_common/const';
import { AuthRoute } from '../_components/routing/auth.routing';
import AppSidebar from '../_components/sidebar/app.sidebar';
import { AppToolbar } from '../_components/toolbar/app.toolbar';
import { historyHelper } from '../_helpers';
import { accountService } from '../_services/account.service';
import { AccountIndex } from './account';
import { ReportIndex } from './report/report-index';
import { DashboardIndex } from './dashboard/dashboard-index';
import { ReportView } from './report/report-view';
import { ReportOverweightView } from './report/report_overweight-view';
import { ReportSurchargeView } from './report/report_surcharge-view';
import { ReportPdf } from './report/report-pdf';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({

    appScreen: {
        display: 'flex',
        height: "100%",
        backgroundColor: "#e6e6e6"
    },
    main: {
        marginTop: "40px"
    },
    root: {

    },
    appLogo: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    mainBackground: {},
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        borderTop: '1px solid #e6e6e6',
        zIndex: 9999
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: "#e6e6e6",
        paddingTop: theme.spacing(5),
        width: 'calc(100% - 650px)',
        height: "fit-content",
        maxWidth: "calc(100%)",
        [theme.breakpoints.up('md')]: {
        },
        [theme.breakpoints.down('md')]: {
            paddingTop: theme.spacing(6),
        },
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0
    }
}));


export function AppScreen() {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [isOpen, setIsOpen] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e: any, nav: any) => {
        setAnchorEl(null);
        if (nav && nav.url)
            goTo(nav.url);
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    }


    function goTo(link: string) {
        historyHelper.push('/' + link);
    }

    return (
        <div className={classes.appScreen} id="app-screen">
            <CssBaseline />

            <AppToolbar toggleSidebar={toggleSidebar} isOpen={isOpen}></AppToolbar>

            <Box display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }}>
                <AppSidebar isOpen={isOpen}></AppSidebar>
            </Box>

            <main className={clsx(classes.content, {
                [classes.contentShift]: isOpen,
            })}>
                <div className={classes.toolbar} />

                <Switch>
                    <AuthRoute exact path="/Dashboard" component={DashboardIndex} />
                    <AuthRoute exact path="/" component={ReportIndex} />
                    <AuthRoute exact path="/Accounts" component={AccountIndex} />
                    <AuthRoute exact path="/Report/View/:id" component={ReportView} />
                    <AuthRoute exact path="/Report/PDF/" component={ReportPdf} />
                    <AuthRoute exact path="/Report/View/Overweight/:id" component={ReportOverweightView} />
                    <AuthRoute exact path="/Report/View/Surcharge/:id" component={ReportSurchargeView} />
                    <AuthRoute path="/Reports" component={ReportIndex} />

                    <AuthRoute component={ReportIndex} />
                </Switch>

            </main>

            <Hidden mdUp>
                <BottomNavigation
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    showLabels={true}
                    className={classes.stickToBottom}
                >

                    {/* {PRIMARY_NAV && PRIMARY_NAV.map(nav =>
                            <BottomNavigationAction key={"bottomNav_" + nav.name} onClick={() => goTo(nav.url)} label={nav.mobileName || nav.name} icon={
                                <>
                                    {nav.png == null &&
                                        <Icon>{nav.icon}</Icon>
                                    }
                                </>
                            } />
                        )} */}

                    {accountService.isAdmin &&
                        <BottomNavigationAction label={'Home'} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} icon={
                            <Icon>apps</Icon>
                        } />
                    }
                    {accountService.isAdmin &&
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >

                            {ADMIN_NAV && ADMIN_NAV.map(nav =>
                                <MenuItem key={"sideNav_" + nav.name} onClick={(e: any) => handleClose(e, nav)}>{nav.name}</MenuItem>
                            )}

                        </Menu>
                    }

                    {USER_NAV && USER_NAV.map((nav: any) =>
                        <BottomNavigationAction key={"bottomNav_" + nav.name} onClick={nav.onClick} label={nav.mobileName || nav.name} icon={
                            <>
                                {nav.png == null &&
                                    <Icon>{nav.icon}</Icon>
                                }
                            </>
                        } />
                    )}

                </BottomNavigation>
            </Hidden>

        </div >
    );
}