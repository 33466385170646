
import { Container, LinearProgress, Paper } from '@material-ui/core';
import { Buffer } from 'buffer';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toastr } from '../../_components/toastr/toastr';
import { reportLibraryService } from '../../_services/reportLibrary.service';

export function ReportPdf() {
    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(true);

    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);

    const wtf = searchParams.get("wtf");
    const invoice = searchParams.get("invoice");
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    const companyId = searchParams.get("companyId");
    const customerIds = searchParams.get("customerIds");
    const reportType = searchParams.get("reportType");

    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {
        let _reportDestination = "err";

        switch (reportType) {
            case ("Acceptance"): _reportDestination = "getAcceptance"; break;
            case ("Destruction"): _reportDestination = "getDestruction"; break;
            case ("Summary"): _reportDestination = "getShippingSummary"; break;
            default: _reportDestination = "err"; break;
        }

        if (!_reportDestination)
            toastr.alert("invalid end point");

        return (reportLibraryService as any)[_reportDestination]({ wtf: wtf, startDate: startDate, endDate: endDate, invoice: invoice, companyId: companyId, customerIds: customerIds })
            .then(
                (json: any) => {
                    var uri = "data:application/pdf;base64," + json;
                    setData(uri);
                },

                (e: any) => {
                    toastr.error("Error getting report data");
                }
            )
            .finally(() => setLoading(false));
    }

    return (
        <Container component="main">
            <h2 className="report-view-title">View PDF Report</h2>


            <Paper>

                {isLoading && <LinearProgress />}


                {data &&
                    <iframe width="100%" style={{ 'height': 'calc(100vh - 200px)' }} src={data}></iframe>
                }
            </Paper>

        </Container>
    );
}
