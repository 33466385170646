import { IconButton } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { useEffect, useState } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { AccountManage } from '../../_common/models/account/accountManage.model';
import { toastr } from '../../_components/toastr/toastr';
import { accountService } from '../../_services/account.service';
import { userService } from '../../_services/user.service';
import { PrimaryButton } from '../../_components/buttons/primary.button';
import { IDropdownData } from '../../_common/interface';
import { useDrawerStyles } from '../../_styles';
import { SectionHeadingDrawer } from '../../_components/drawer/sectionHeading.drawer';
import Icon from '@material-ui/core/Icon';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { RESET_PASSWORD_ERROR_MESSAGES, RESET_PASSWORD_HELPER_TEXT, RESET_PASSWORD_VALIDATORS } from '../../_common/const/security.const';
import { useParams } from 'react-router-dom';
import { CompanyAutoComplete } from '../../_components/autoComplete/companies/company.autocomplete';

export function AccountEdit(params: { accountId: string, emitOnSave: () => void, emitOnClose: () => void }) {

    const [isLoading, setLoading] = useState(false);
    const [accountData, setAccountData] = useState<Partial<AccountManage>>(resetAccountData);
    const [passwordChange, setPasswordChange] = useState(false);
    const { accountId } = useParams<{ accountId: string }>();
    const [companies, setCompanies] = useState<Array<IDropdownData>>([]);

    const classes = useDrawerStyles();

    useEffect(() => {
        if (params.accountId && params.accountId.length > 0) {
            fetchData();
        }
    }, [accountId]);

    function fetchData() {
        setLoading(true);
        return userService
            .get(params.accountId)
            .then(
                (json: any) => {
                    json.customerIds = json.customerIds.join(",");
                    setAccountData(json);                                      
                    setCompanies(json.companies);                    
                },
                (e) => {
                    toastr.error("Error getting account data");
                }
            )
            .finally(() => setLoading(false));
    }
   
    function resetAccountData() {
        return {
            name: "",
            firstName: "",
            lastName: "",
            password: "",
            confirmPassword: "",
            email: "",
            role: "",
            roles: "",
            customerId: null as string,
            customerIds: ""
        };
    }

    function submit(e: any) {
        e.preventDefault();
        saveAccount(true);
    }

    function disable(e: any) {
        e.preventDefault();
        saveAccount(false);
    }

    function saveAccount(isActive: boolean) {
        setLoading(true);
        var _account = accountData;
        _account.isActive = isActive;
        _account.roles = [];
        _account.roles.push(_account.role || "");
        accountService
            .submit(_account)
            .then(
                (d) => { console.log(d); resetAccountData(); params.emitOnSave(); },
                (e) => { console.log(e); setLoading(false); toastr.error("Something went wrong. Please try again later") }
            );
    }

    function handleChange(event: any) {
        var _account = accountData;
        _account[event.target.name] = event.target.value;
        setAccountData({ ..._account });
    };   

    function closeModal() {
        resetAccountData();
        params.emitOnClose();
    }

    function selectCompanies(companies: Array<any>) {
        setAccountData(_data => {
            let companyIds = companies && companies.length ? companies.map(m => m.id) : [];
            console.log("companyIds", companyIds);
            var companyIdsJoined = companyIds.join(",");
            return { ..._data, customerIds: companyIdsJoined }
        })

        setCompanies(companies);
    }

    return (
        <>
            <Drawer anchor="right" open={true} onClose={closeModal} variant="temporary" className={classes.root} disableEnforceFocus>

                <SectionHeadingDrawer heading={params.accountId.length > 0 ? "Update User" : "Create New User"}></SectionHeadingDrawer>
                <IconButton aria-label="close" className={classes.closeButton} onClick={closeModal}>
                    <Icon>close</Icon>
                </IconButton>
                <ValidatorForm onSubmit={submit} noValidate>

                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <TextValidator
                                        autoComplete="off"
                                        variant="filled"
                                        margin="normal"
                                        fullWidth
                                        label="First Name"
                                        name="firstName"
                                        value={accountData.firstName}
                                        disabled={isLoading}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextValidator
                                        autoComplete="off"
                                        variant="filled"
                                        margin="normal"
                                        fullWidth
                                        label="Last Name"
                                        name="lastName"
                                        value={accountData.lastName}
                                        disabled={isLoading}
                                        onChange={handleChange}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <TextValidator
                                autoComplete="off"
                                variant="filled"
                                margin="normal"
                                required
                                fullWidth
                                label="Email Address"
                                name="email"
                                value={accountData.email}
                                onChange={handleChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                            {params.accountId.length > 0 && <FormControlLabel control={<Checkbox checked={passwordChange} onChange={() => setPasswordChange(!passwordChange)} />} label="Change Password" />}

                            {(passwordChange === true || (params.accountId.length === 0)) &&
                                <TextValidator
                                    autoComplete="new-password"
                                    variant="filled"
                                    margin="normal"
                                    fullWidth
                                    label="Password"
                                    name="password"
                                    value={accountData?.password}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    type="password"
                                    validators={RESET_PASSWORD_VALIDATORS(true)}
                                    errorMessages={RESET_PASSWORD_ERROR_MESSAGES(true)}
                                    helperText={!accountData?.password ? "Only Update if password change is required. " + RESET_PASSWORD_HELPER_TEXT : "✓ Password is valid"} />
                            }
                            <SelectValidator
                                id="demo-simple-select"
                                value={accountData?.role}
                                onChange={handleChange}
                                required
                                label="Select Role"
                                name="role"
                                fullWidth
                                validators={['required']}
                                errorMessages={['This field is required']}
                                variant="filled">
                                <MenuItem value='Admin'>Admin</MenuItem>
                                <MenuItem value='User'>User</MenuItem>
                            </SelectValidator>

                            {(accountData && accountData.role === 'User') &&
                                <Grid item xs={12} sm={12}>                                 
                                    <CompanyAutoComplete
                                        name={"customerId"}
                                        multipleValues={companies}
                                        onSelect={selectCompanies}
                                        hasParent={false}
                                        variant="filled"
                                        isRequired
                                    />
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={accountData.isActive === false || params.accountId.length === 0 ? 12 : 6}>
                            <PrimaryButton
                                type="submit"
                                variant="primary"
                                disabled={isLoading}>
                                <Icon>check</Icon> {accountData?.isActive === false ? <>Save and Reactivate</> : <>Save</>}
                            </PrimaryButton>
                        </Grid>
                        {(accountData?.isActive === true) &&
                            <Grid item xs={6}>
                                <PrimaryButton
                                    type="button"
                                    variant="danger"
                                    disabled={isLoading}
                                    onClick={disable}
                                >
                                    <Icon>remove</Icon> Disable
                                </PrimaryButton>
                            </Grid>
                        }
                    </Grid>
                </ValidatorForm>
            </Drawer >
        </>
    );
}
