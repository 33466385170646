import { Container, Paper, TablePagination } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import { TableFilterEnum } from '../../_common/enum/tableFilter.enum';
import { IFilteringViewModel, ITableData } from '../../_common/interface/iTableFilter.interface';
import { PaperHeader } from '../../_components/paperComponents/header.paperComponent';
import { CommonTableHead, CommonTablePagination } from '../../_components/table';
import { CommonTable } from '../../_components/table/table';
import { toastr } from '../../_components/toastr/toastr';
import { getDateStringNormalized, getTableOptions, historyHelper } from '../../_helpers';
import { userService } from '../../_services/user.service';
import { useIndexStyles } from '../../_styles/index.styles';
import { AccountEdit } from './account-edit';


export function AccountIndex() {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 100, page: 0, order: 'desc', orderBy: '' });
    const [accountId, setAccountId] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const classes = useIndexStyles();

    const columns = [
        { title: 'Name', field: 'name' },
        { title: 'Email', field: 'email' },
        { title: 'Company', field: 'companyName' },
        { title: 'Created Date', field: 'createdDate', render: (rowData: any) => <>{getDateStringNormalized(rowData.createdDate, true)}</> },
        { title: 'Status', field: 'isActive', render: (rowData: any) => <>{rowData.isActive ? "Active" : "Inactive"}</> },
    ];

    useEffect(() => {
        fetchData()
    }, [filter]);

    function fetchData() {

        setLoading(true);

        return userService.getAll(filter)
            .then(data => {
                data.items = data.items || [];
                setData(data);
            },
                (e) => { console.log(e); toastr.error("Something went wrong. Please try again later") })
            .finally(() => setLoading(false));
    }

    function setProp(propName: TableFilterEnum, propValue: any) {
        let f = { ...filter, [propName.toString()]: propValue };
        if (propName === TableFilterEnum.search) f.page = 0;
        setFilter(f);
    }



    function createUser() {
        setAccountId('');
        setIsEditing(true);
    }

    function updateUser(accountId: any) {
        setAccountId(accountId);
        setIsEditing(true);
    }

    function emitOnEditSave() {
        toastr.success("User details saved successfully");
        setIsEditing(false);
        setAccountId('');
        fetchData();
    }

    function emitOnEditClose() {
        setIsEditing(false);
        setAccountId('');
        fetchData();
    }
    return (
        <>

            <Container component="main">
                <Paper>
                    <CssBaseline />

                    <div className={classes.pageHeader}>
                        <PaperHeader title='Users' icon='person_add'></PaperHeader>
                    </div>

                    <CommonTable
                        title={null}
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        setFilter={setFilter}
                        onRowClick={(e: any, data: any) => updateUser(data.id)}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add User',
                                isFreeAction: true,
                                onClick: (event: any) => createUser()
                            }
                        ]}
                    />
                </Paper>

                {isEditing &&
                    <AccountEdit accountId={accountId} emitOnSave={emitOnEditSave} emitOnClose={emitOnEditClose} />
                }

            </Container>
        </>
    );
}