import { Checkbox, Chip, Icon, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { IDropdownData } from '../../_common/interface/iDropdownData.interface';
import { IStandardAutocomplete } from '../../_common/interface/autocomplete/iStandardAutocomplete.interface';
import SearchIcon from '@material-ui/icons/Search';

interface IMultipleExtAutocomplete extends IStandardAutocomplete {
    itemsLoader: (parentId?: number) => Promise<any>,
    label: string,
    isTiny?: boolean,
    onSelect?(values: Array<any>): void,
    multipleValues?: Array<any>
}

const useStyles = makeStyles((theme: Theme) => ({

    tinyAutocomplete: {
        "& .MuiFilledInput-root": {
            height: "30px",
            paddingTop: "7px !important"
        },
        "& label": {
            top: "-7px"
        }
    },
}));

export const MultipleAutoComplete = (params: IMultipleExtAutocomplete) => {

    const [isLoading, setLoading] = useState(false);
    const [items, setItems] = useState<Array<IDropdownData>>([]);

    const icon = <Icon fontSize="small">check_box_outline_blank</Icon>;
    const checkedIcon = <Icon fontSize="small"> check_box</Icon>;
    const searchIcon = <Icon fontSize="small">search</Icon>
    const classes = useStyles();

    useEffect(() => {
        if (params.hasParent === true) {
            if (params.parentId > 0) {
                fetchItems(params.parentId);
            }
            else if (items.length > 0) {
                setItems([]);
            }
        }
        else {
            fetchItems();
        }
    }, [params.parentId]);

    useEffect(() => {
        console.log("re", params.multipleValues)
    }, [params.multipleValues]);
    function fetchItems(parentId?: number) {
        setLoading(true);
        return params.itemsLoader(parentId)
            .then(
                (json) => {
                    if (!json || json.length < 1) {
                        setItems([]);
                    }
                    else {
                        setItems(json);
                    }
                },
                (err) => {
                    console.error("asd", err);
                    setItems([]);
                }
            )
            .finally(() => setLoading(false));
    }


    return (
        params.isHiddenIfEmpty && (!items || items.length == 0) ?
            null :
            <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={params.multipleValues && params.multipleValues.length ? items.filter(m => !params.multipleValues.some(r => r.id == m.id)) : items}
                value={params.multipleValues}
                disableCloseOnSelect
                filterSelectedOptions
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => { params.onSelect(v); }}
                renderOption={(option, { selected }) => (
                    <>
                        <span>
                            {option.name}
                        </span>
                    </>
                )}
                //<Checkbox
                //    icon={icon}
                //    checkedIcon={checkedIcon}
                //    style={{ marginRight: 8 }}
                //    checked={selected} />
                //{option.name}

                renderTags={(value, getTagProps) =>
                    value.map((option, index: number) => (
                        <Chip
                            variant="outlined"
                            label={option.name}
                            {...getTagProps({ index })}
                        />
                    ))
                }
                renderInput={(props) =>
                (<TextField {...props}
                    variant={params.variant}
                    label="Companies"
                    placeholder="Search Companies... &#x1F50D; "
                    fullWidth
                    //InputProps={{
                    //    startAdornment: (
                    //        <InputAdornment position="end">
                    //            <SearchIcon/>
                    //        </InputAdornment>
                    //    ),
                        
                    //}}
                />)}
            />
    );
}