import { Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';

import React from 'react';
import { Route } from "react-router-dom";
import { PRIMARY_COLOR, THEME } from '../_common/const';
import { loginStyles } from '../_styles/login.styles';
import { LoginForgotPassword } from './login/login-forgotPassword';
import { LoginIndex } from './login/login-index';
import { LoginResetPassword } from './login/login-resetPassword';
import { AuthFooter } from './_components/authFooter.screen';


const logoImg = require('../_assets/images/Logo_1_black_text.png');

export const AuthScreen = () => {
    const classes = loginStyles(); // background image is defined in here

    return (
        <div className={classes.appScreen} id="app-screen">
            <Container component="main" maxWidth="sm" className={classes.mainBackground}>
                <div className={classes.ieBox}>
                    <Paper elevation={3} className={classes.logoBox}>
                        <img className={classes.appLogo} src={logoImg}></img>
                    </Paper>

                    <Paper elevation={3} className={classes.loginBox}>

                        <CssBaseline />

                        <Route exact path="/login" component={LoginIndex} />
                        <Route exact path="/login/forgotpassword" component={LoginForgotPassword} />
                        <Route path="/login/resetpassword/:code" component={LoginResetPassword} />

                        <Box mt={8}>
                            <AuthFooter />

                        </Box>
                    </Paper>
                </div>
            </Container>
        </div>
    );
}