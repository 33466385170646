import { Divider, Drawer, Icon, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';

import { ADMIN_NAV, PRIMARY_NAV, USER_NAV } from '../../_common/const';
import { historyHelper } from '../../_helpers';
import { accountService } from '../../_services/account.service';

const logoImg = require('../../_assets/images/Logo_1_black_text.png');


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },

    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    logo: {
        padding: "16px",
        color: theme.palette.primary.main
    },
    appLogo: {
        width: "calc(240px * 0.50)",
        marginTop: "0px",
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function AppSidebar(params: { isOpen: boolean }) {
    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(params.isOpen);

    function goTo(link: string) {
        historyHelper.push('/' + link);
    }

    useEffect(() => {
        setIsOpen(params.isOpen);
    }, [params.isOpen]);

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            open={isOpen}
            id={"sidebarNavDrawer"}

            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="left"
        >
            <div className={classes.toolbar} >
                <img className={classes.appLogo} src={logoImg} alt={"test"}></img>
            </div>

            <Divider />

            {(PRIMARY_NAV.length > 0) &&
                <List>
                    {PRIMARY_NAV.map(nav =>
                        <ListItem button key={nav.name} onClick={e => goTo(nav.url)}>
                        <ListItemIcon><Icon>{nav.icon}</Icon></ListItemIcon>
                        <ListItemText primary={nav.name} />
                    </ListItem>
                    )}
                    <Divider />
                </List>
            }

            {(ADMIN_NAV.length > 0 && accountService.isAdmin === true) &&
                <List>
                    {ADMIN_NAV.map(nav =>
                        <ListItem button key={nav.name} onClick={e => goTo(nav.url)}>
                            <ListItemIcon><Icon>{nav.icon}</Icon></ListItemIcon>
                            <ListItemText primary={nav.name} />
                        </ListItem>
                    )}

                    <Divider />
                </List>
            }

            {(USER_NAV.length > 0) &&
                <List>
                    {USER_NAV.map(nav =>
                        <ListItem button key={nav.name} onClick={nav.onClick}>
                            <ListItemIcon><Icon>{nav.icon}</Icon></ListItemIcon>
                            <ListItemText primary={nav.name} />
                        </ListItem>
                    )}
                    <Divider />
                </List>
            }
        </Drawer>
    );
}