
//export const PRIMARY_COLOR = ["#102843", "#1B2F52"];
export const PRIMARY_COLOR = ["#2f9ad4", "#1B2F52"];
export const SECONDARY_COLOR = ["#f44336", "#d32f2f"];
export const WARNING_COLOR = ["#FFC107", "#E5AD06", "#ffdf7f"];
export const DANGER_COLOR = ["#ed1c23", "#d32f2f", "#f89e97"];
export const SUCCESS_COLOR = ["#4caf50", "#388e3c"];
export const INFO_COLOR = ["#2196f3", "#1976d2"];
export const ACTUAL_COLOR = ["#4caf50", "#f44336", "#FFC107", "#00FFFF", "#DCDCDC", "#990033"];
export const BLAC_COLOR = "#000";
export const WHITE_COLOR = "#FFF";
export const PINK_COLOR = "#f8d7da";
export const PURPLE_COLOR = ["#513f8f", "#382c64"];