import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

interface Props {
    color?: "primary" | "secondary";
    hex?: string;
    value?: number;
}

/**
 * ProgressBar -- atom
 * A material-ui progress bar.
 * @param {'primary' | 'secondary'} color Choose to render the primary or secondary colors.
 * @param {string} hex Override the selected color with a hex value.
 * @param {number} value The value for the progress bar, between 0-100.
 * @return {JSX} React component
 */

const ProgressBarRed = ({ color, hex, value }: Props) => {

    const useStyles = makeStyles((theme) => ({

        root: {
            border: `1px solid ${theme.palette.divider}`,
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
            height: 26,
            borderRadius: 4,
        },
        bar: {
            height: '100%',
            '&.low': {
                backgroundColor: '#f44336',
            },
            '&.medium': {
                backgroundColor: '#efbb5aa3',
            },
            '&.high': {
                backgroundColor: '#088208a3',
            },
            '&.top': {
                backgroundColor: '#f44336',
            },
        },
        value: {
            position: 'absolute',
            lineHeight: '24px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center !important',
            margin: '0 auto'
        },
    }))

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.value}>{`${value.toLocaleString()} %`}</div>
            <div
                className={clsx(classes.bar, {
                    low: value < 30,
                    medium: value >= 30 && value <= 70,
                    high: value > 70,
                    top: value == 100
                })}
                style={{ maxWidth: `${value}%` }}
            />
        </div>
    );
};

export default ProgressBarRed;

