import { makeStyles } from '@material-ui/core/styles';

export const useReportViewStyles = makeStyles(theme => ({

    appBar: {
        top: "auto",
        bottom: 0
    },
    title: {
        flexGrow: 1,
        textAlign: "center",
        padding: '20px',
        fontWeight: 'bold',
        color: '#40200e !important',
    },
    headTitle: {
        flexGrow: 1,
        textAlign: "center",
        padding: '20px',
        fontWeight: 'bold',
        color: '#184d6a',
        backgroundColor: '#e1d7d1 !important',
        border: '1px solid lightgrey',
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 5px",

    },
    subtitle: {
        color: '#40200e !important',
        textIndent: '10px',
        textDecoration: 'underline',
        padding: '16px'
    },


}));