import { makeStyles } from '@material-ui/core/styles';

export const useChartStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    reportChartPaper: {
        padding: theme.spacing(2),
        borderRadius: '10px'
    },
    reportChartAvatar: {
        width: '55px !important',
        height: '55px !important',
        marginLeft: '10px',
    },

    shipmentPaper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
        backgroundColor: 'rgba(13, 110, 253, 0.4)',
        borderRadius: '10px'

    },
    weightPaper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
        backgroundColor: 'rgba(255, 193, 7, 0.4)',
        borderRadius: '10px'

    },
    overweightPaper: {
        padding: theme.spacing(2),
        margin: 'auto',
        backgroundColor: 'rgba(220, 53, 69, 0.4)',
        borderRadius: '10px'

    },

    shipmentAvatar: {
        backgroundColor: 'rgb(13, 110, 253) !important',
        width: '55px !important',
        height: '55px !important',
        marginLeft: '10px',

    },
    weightAvatar: {
        backgroundColor: 'rgb(255, 193, 7) !important',
        width: '55px !important',
        height: '55px !important',
        marginLeft: '10px'

    },
    overweightAvatar: {
        backgroundColor: 'rgb(220, 53, 69) !important',
        width: '55px !important',
        height: '55px !important',
        marginLeft: '10px'
    },

    alertsContainer: {
        paddingBottom: '10px',
        paddingRight: '10px',
    },

    alertsContainer2: {
        paddingBottom: '10px',
        paddingRight: "10px",
        paddingTop: "2px"
    },

    description: {
        marginLeft: '10px',
        fontSize: 'large'
    },

    chartParentContainer: {
        marginBottom: '250px'
    },

    chartContainer: {
        height: '110px', marginBottom: '200px'
    },

    chartBox: {
        flexGrow: 1, width: '100%', marginBottom: '20px'
    },

    chartPaper: {
        padding: theme.spacing(2),
        marginBottom: '30px',
    },

    dashboardTab: {
        minWidth: 50, 
        width: 80
    },

    dashboardMain: {
        marginTop: "10px"
    }

}));