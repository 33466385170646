import { BehaviorSubject } from 'rxjs';
import { API_URL, AUTH_URL } from '../_common/const/config.const';
import { RoleEnum } from '../_common/enum';
import { AccountManage } from '../_common/models';
import { getBody } from '../_helpers/body-helper';
import { handleResponse } from '../_helpers/handle-response';
import { getHeader } from '../_helpers/header.helper';

const currentUserSubject = new BehaviorSubject(localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== "undefined" ? (JSON.parse(localStorage.getItem('currentUser') ?? "")) : null);
console.log("current user", currentUserSubject.value);
const API_NAME = "account";

export const accountService = {
    login,
    logout,
    forgotPassword,
    resetPassword,
    post,
    put,
    submit(model: Partial<AccountManage>) { return model.id ? put(model) : post(model) },
    disable,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value },
    get isAdmin() { return (currentUserSubject.value && currentUserSubject.value.roles.length && currentUserSubject.value.roles.some((a: any) => a === RoleEnum.Admin)) },
    isInRole(role: RoleEnum) {
        return (currentUserSubject.value && currentUserSubject.value.roles.length && currentUserSubject.value.roles.some((a: any) => a.toLowerCase() === role.toString().toLowerCase()))
    },
    get companiesCount() {
        var elements = currentUserSubject.value.customerIds.split(",");
        return elements.length;
    }
};

function login(email: string, password: string, rememberMe: boolean) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email, password: password, rememberMe: rememberMe })
    };

    return fetch(`${AUTH_URL}/account/authenticate`, requestOptions)
        .then(handleResponse, function (err) { console.log(err); })
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);
            return user;
        });
}

function post(model: Partial<AccountManage>) {
    console.log('post : ', model);
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/create`, requestOptions).then(handleResponse);
}

function put(model: Partial<AccountManage>) {
    console.log('putt : ', model);
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/update`, requestOptions).then(handleResponse);
}

function disable(accountId: any) {
    const requestOptions = { method: 'DELETE', headers: getHeader() };
    return fetch(`${AUTH_URL}/account/disable/?userId=${accountId}`, requestOptions).then(handleResponse);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    window.location.href = "login";
}

function forgotPassword(email: string) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email })
    };

    return fetch(`${AUTH_URL}/account/forgotpassword`, requestOptions)
        .then(handleResponse);
}

function resetPassword(email: string, password: string, confirmPassword: string, code: string) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email, password: password, confirmPassword: confirmPassword, code: code })
    };

    return fetch(`${AUTH_URL}/account/resetpassword`, requestOptions)
        .then(handleResponse);
}
