import * as React from 'react';
import { Component, useState } from 'react';
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { toastr } from '../toastr/toastr';
import { dashboardService } from '../../_services/dashboard.service';
import { LinearProgress } from '@material-ui/core';

export default function MultiaxisBarChart(params: { search: any }) {

    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState({ month: [], weight: [], overweight: [] });

    React.useEffect(() => {
        fetchData();
    }, [params.search]);

    function fetchData() {

        setLoading(true);

        return dashboardService.getReportChart(params.search)
            .then(data => {
                console.log(data);
                setData(data);
            },
                (e) => {
                    console.log(e);
                    toastr.error("Something went wrong. Please try again later")
                })
            .finally(() => setLoading(false));
    }

    function format(num: number) {
        return String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');
    } 

    const chartData: ApexOptions = {
        chart: {
            toolbar: {
                show: false
            },
            height: 150,
            type: 'line',
            stacked: false
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 370,
                    height: 300,
                    offsetY: 20,
                    offsetX: -30
                },
                legend: {
                    position: 'bottom'
                },
                title: {
                    text: "Jobs Weight Analysis",
                    align: 'center',
                    offsetY: 0,
                    offsetX: 0
                },
            },
        }],
        colors: ['rgb(255, 193, 7)', 'rgb(220, 53, 69)'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [1, 1, 4]
        },
        title: {
            text: 'Reports Weight Analysis (January - December)',
            align: 'left',
            offsetX: 110
        },
        xaxis: {
            categories: data.month
        },
        yaxis: [
            {
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: 'grey'
                },
                labels: {
                    style: {
                        colors: ['black']
                    },
                    formatter: function(value) {
                        return format(value);
                    }
                },
                title: {
                    text: "Weight (kg)",
                    style: {
                        color: 'grey',
                    }
                },
                tooltip: {
                    enabled: false
                }
            },
            {
                seriesName: 'Weight',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: 'grey'
                },
                labels: {
                    style: {
                        colors: 'grey',
                    },
                    formatter: function (value) {
                        return format(value);
                    }
                },
                title: {
                    text: "Weight (kg)",
                    style: {
                        color: 'grey',
                    }
                },
            }

        ],
        tooltip: {
            fixed: {
                enabled: true,
                position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                offsetY: 30,
                offsetX: 60
            },
        },
        legend: {
            horizontalAlign: 'left',
            offsetX: 40
        },
        fill: {
            colors: ['rgb(255, 193, 7)', 'rgb(220, 53, 69)'],
            type: "gradient",
            gradient: {
                shade: "light",
                type: "horizontal",
                shadeIntensity: 0.3,
                gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 100]
                // colorStops: []
            }
        },
        series: [{
            name: 'Weight(kg)',
            type: 'column',
            data: data.weight
        }]
    };

    return <>
        {data.weight.length != 0 &&
            <>
                {isLoading && <LinearProgress />}
                <ReactApexChart options={chartData} height={326} series={chartData.series} />
            </>
        }
        {data.weight.length == 0 &&
            <div>
                <p style={{ textAlign: 'center' }}>No weight data available.</p>
            </div>
        }
    </>
};


