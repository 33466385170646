import { Box, Grid, Icon, LinearProgress, Paper } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import WeightByEwcChart from '../../../_components/charts/weightByEwcChart';
import RecoveryDisposalChart from '../../../_components/charts/recoveryDisposalChart';
import TonnageByYearsChart from '../../../_components/charts/tonnageByYearsChart';
import { toastr } from '../../../_components/toastr/toastr';
import { accountService } from '../../../_services/account.service';
import { dashboardService } from '../../../_services/dashboard.service';
import { useChartStyles } from '../../../_styles/chartStyles';
import ReportChartUserInfo from '../components/report-chart-users_info';
import MultiaxisBarChart from '../../../_components/charts/multiaxisBarChart';
import { IDropdownData } from '../../../_common/interface';


export default function ReportChartHorizontal(params: { search: any }) {

    const classes = useChartStyles();

    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState({ recoveryWeights: [], disposalWeights: [] });
    const [ewcData, setEwcData] = useState({ weight: 0, ewc: "" });
    const [reportData, setReportData] = useState({ weight: 0, overweight: 0, shipments: 0, reports: 0 });
    const [recoveryWeight, setRecoveryWeight] = useState(null);
    const [disposalWeight, setDisposalWeight] = useState(null);
    const [companies, setCompanies] = useState<Array<IDropdownData>>([]);

    useEffect(() => {
        fetchRecoveryDisposalData();
        fetchReportData();
        fetchEwcData();
    }, [params.search]);

    function fetchEwcData() {

        setLoading(true);

        return dashboardService.getReportChartEwc(params.search)
            .then(data => {
                setEwcData(data);
            },
                (e) => {
                    console.log(e);
                    toastr.error("Something went wrong. Please try again later")
                })
            .finally(() => setLoading(false));
    }

    function fetchRecoveryDisposalData() {
        var recoveryWeightSum = 0;
        var disposalWeightSum = 0;
        setLoading(true);

        return dashboardService.getReportChartRecoveryDisposal(params.search)
            .then(data => {
                setData(data);

                for (var i = 0; i < data.recoveryWeight.length; i++) {
                    recoveryWeightSum = recoveryWeightSum + data.recoveryWeight[i];
                }
                setRecoveryWeight(recoveryWeightSum);

                for (var i = 0; i < data.disposalWeight.length; i++) {
                    disposalWeightSum = disposalWeightSum + data.disposalWeight[i];
                }
                setDisposalWeight(disposalWeightSum);
            },
                (e) => {
                    console.log(e);
                    toastr.error("Something went wrong. Please try again later")
                })
            .finally(() => setLoading(false));
    }

    function fetchReportData() {

        setLoading(true);

        return dashboardService.getReportGraphs(params.search)
            .then(data => {
                console.log("report chart:", data)
                setReportData(data);
                setCompanies(data.companies);
            },
                (e) => {
                    console.log(e);
                    toastr.error("Something went wrong. Please try again later")
                })
            .finally(() => setLoading(false));
    }

    function format(num: number) {
        return String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');
    } 

    return (
        <>
            <div className={classes.root}>
                <Grid spacing={1} container>
                    <Grid spacing={1} md={6} xs={12}>
                        <Grid spacing={0} container>
                            

                            <Grid spacing={0} md={6} xs={12}>
                                <Grid md={12} xs={12} className={classes.alertsContainer2}>
                                    <ReportChartUserInfo
                                        title="Jobs"
                                        isLoading={isLoading}
                                        secondaryTitle={`${reportData.reports}`}
                                        backgroundColor='rgba(25, 135, 84, 0.4)'
                                        iconColor='rgb(25, 135, 84)'
                                        icon='summarize'
                                    />
                                </Grid>
                                <Grid md={12} xs={12} className={classes.alertsContainer2}>
                                    <ReportChartUserInfo
                                        title="Total Weight"
                                        isLoading={isLoading}
                                        secondaryTitle={`${format(reportData.weight)}kg`}
                                        backgroundColor='rgba(255, 193, 7, 0.4)'
                                        iconColor='rgb(255, 193, 7)'
                                        icon='scale'
                                    />
                                </Grid>                               
                            </Grid>

                            <Grid spacing={0} md={6} xs={12}>
                                <Grid md={12} xs={12} className={classes.alertsContainer2}>
                                    <ReportChartUserInfo
                                        isLoading={isLoading}
                                        title="Recovery Weight"
                                        secondaryTitle={`${format(recoveryWeight)}kg`}
                                        backgroundColor='rgba(153, 51, 204, 0.4)'
                                        iconColor='rgb(153, 51, 204)'
                                        icon='monitor_weight'
                                    />
                                </Grid>

                                <Grid md={12} xs={12} className={classes.alertsContainer2}>
                                    <ReportChartUserInfo
                                        isLoading={isLoading}
                                        title="Disposal Weight"
                                        secondaryTitle={`${format(disposalWeight)}kg`}
                                        backgroundColor='rgba(84, 180, 211, 0.4)'
                                        iconColor='rgb(84, 180, 211)'
                                        icon='delete_sweep'
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid md={6} xs={12}>
                        <Grid item xs={12}>
                            <Paper className={classes.chartPaper}>
                                <Grid xs={12} md={12} lg={12}>
                                    <RecoveryDisposalChart search={params.search} />
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid md={6} xs={12} style={{ paddingRight: '15px' }}>
                        <Grid item xs={12}>
                            <Paper className={classes.chartPaper}>
                                <Grid xs={12} md={12} lg={12}>
                                    <MultiaxisBarChart search={params.search} />
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid md={6} xs={12}>
                        <Grid item xs={12}>
                            <Paper className={classes.chartPaper}>
                                <Grid xs={12} md={12} lg={12}>
                                    <TonnageByYearsChart search={params.search} />
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid md={12} xs={12}>
                        <Grid item xs={12}>
                            <Paper className={classes.chartPaper}>
                                <Grid xs={12} md={12} lg={12}>
                                    <WeightByEwcChart search={params.search} />
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

