import * as React from 'react';
import { Component, useState } from 'react';
import Chart from 'react-apexcharts';
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { toastr } from '../toastr/toastr';
import { dashboardService } from '../../_services/dashboard.service';
import { LinearProgress } from '@material-ui/core';

export default function WeightByEwcChart(params: { search: any }) {

    const [isLoading, setLoading] = useState(false);
    const [ewcData, setEwcData] = useState({ weight: [], ewc: [] });

    React.useEffect(() => {
        fetchEwcData();
    }, [params.search]);

    function fetchEwcData() {

        setLoading(true);

        return dashboardService.getReportChartEwc(params.search)
            .then(data => {
                console.log("ewc data:", data);
                setEwcData(data);
                console.log("array:", ewcData.ewc.length);
            },
                (e) => {
                    console.log(e);
                    toastr.error("Something went wrong. Please try again later")
                })
            .finally(() => setLoading(false));
    }


    function format(num: number) {
        return String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');
    } 

    const chartData: ApexOptions = {
        chart: {
            toolbar: {
                show: false
            },

            height: 250,
            type: 'line',
            stacked: false,
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 370,
                    height: 300,
                    offsetY: 20,
                    offsetX: -30
                },
                legend: {
                    position: 'bottom'
                },
                title: {
                    text: "Weight by Ewc",
                    align: 'center',
                    offsetY: 0,
                    offsetX: 0
                },
            },
        }],
        colors: [
            "#00bfa0",
        ],
        plotOptions: {
            bar: {
                distributed: true, // this line is mandatory
                barHeight: '85%',
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [1, 1, 4]
        },

        title: {
            text: "Weight by Ewc",
            align: 'left',
            offsetX: 110
        },
        xaxis: {
            categories: ewcData.ewc
        },
        yaxis: [
            {
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: 'grey'
                },
                labels: {
                    style: {
                        colors: ['black']
                    },
                    formatter: function (value) {
                        return format(value);
                    }
                },
                title: {
                    text: "Weight (kg)",
                    style: {
                        color: 'grey',
                    }
                },
                tooltip: {
                    enabled: false
                }
            },
            {
                seriesName: 'Weight',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: 'grey'
                },
                labels: {
                    style: {
                        colors: 'grey',
                    },
                    formatter: function (value) {
                        return format(value);
                    }
                },
                title: {
                    text: "Weight (kg)",
                    style: {
                        color: 'grey',
                    }
                },
            }

        ],
        tooltip: {
            fixed: {
                enabled: true,
                position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                offsetY: 30,
                offsetX: 60
            },
        },
        legend: {
            horizontalAlign: 'left',
            offsetX: 40
        },
        fill: {
            colors: ["#00bfa0"],
            type: "gradient",
            gradient: {
                shade: "light",
                type: "horizontal",
                shadeIntensity: 0.3,
                gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 100]
                // colorStops: []
            }
        },
        series: [{
            name: 'Weight(kg)',
            type: 'column',
            data: ewcData.weight
        }]


    };

    //return <ReactApexChart options={chartData} height={400} series={chartData.series} />

    //    //{isLoading && <LinearProgress />}
    

   return <>
      {ewcData.ewc.length != 0 &&
        <>
            { isLoading && <LinearProgress />} 
            <ReactApexChart options={chartData} height={326} series={chartData.series} />
        </>
        }
        {ewcData.ewc.length == 0 &&
            <div>
                <p style={{ textAlign: 'center' }}>No weight by ewc data available for the chosen time period.</p>
            </div>
        }
    </>



};