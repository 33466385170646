import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@material-ui/core/Grid';
import { useEffect, useState } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ReportViewModel } from '../../_common/models/report/reportView.model';
import { toastr } from '../../_components/toastr/toastr';
import { reportService } from '../../_services/report.service';
import { Box, Container, Divider, LinearProgress, ListItem, ListItemText, Paper, Toolbar } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { ITableData } from '../../_common/interface';
import { ReportOverweightView } from '../report/report_overweight-view';
import { ReportSurchargeView } from '../report/report_surcharge-view';
import Moment from 'moment';
import { useBasicTableStyles } from '../../_styles/basicTable.styles';



export function ReportView(params: { id: any }) {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [isLoading, setLoading] = useState(false);
    const [reportData, setReportData] = useState<Partial<ReportViewModel>>(resetReportData);
    const [isNonConformance, setIsNonConformance] = useState(false);

    const { id } = useParams<{ id: any }>();
    const classes = useBasicTableStyles();


    useEffect(() => {
        if (id)
            fetchData();
        fetchWasteData();
    }, [id]);

    function resetReportData() {
        return {
            id: 0,
            acceptanceDate: "",
            acceptanceFacility: "",
            customerName: "",
            invoice: "",
            lorryreg: "",
            location: "",
            wtf: "",
            overweight: false,
            overweightAction: "",
            hasNonConformance: false,
            hasSurcharge: false
        };
    }

    function fetchData() {

        if (!id) return;

        setLoading(true);

        return reportService
            .get(id)
            .then(
                (json) => {
                    setReportData(json);
                    if (json.nonConformanceLines.length > 0) {
                        setIsNonConformance(true);
                    }
                },

                (e) => {
                    toastr.error("Error getting report data");
                }
            )
            .finally(() => setLoading(false));
    }

    function fetchWasteData() {

        if (!id) return;

        setLoading(true);

        return reportService
            .getAllWastesAndTreatments(id)
            .then(data => {
                data.items = data.items || [];
                setData(data);

            },
                (e) => {
                    toastr.error("Error getting account data");
                }
            )
            .finally(() => setLoading(false));
    }



    return (
        <Container component="main" maxWidth={false} disableGutters>
            <h2 className="report-view-title">Waste Report #{reportData.id}</h2>

            {isLoading && <LinearProgress />}

            <Paper>
                <div className="heading-h6">Report Information</div>

                <Grid container spacing={1} className="report-container">
                    <Grid container md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={4} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography><span className="item-title">Customer: </span></ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item md={8} lg={8} sm={8} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography key={reportData.customerName}>{reportData.customerName}</ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>

                    <Divider style={{ width: '98%', margin: '0 auto' }} />

                    <Grid container md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={4} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography><span className="item-title">Acceptance Date: </span></ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item md={8} lg={8} sm={8} xs={6}>
                            <ListItem>
                                {(reportData.acceptanceDate != null && !isLoading) &&
                                    <ListItemText disableTypography key={reportData.acceptanceDate}>{Moment(reportData.acceptanceDate).format('DD/MM/YYYY')}</ListItemText>
                                }
                                {isLoading &&
                                    <ListItemText disableTypography key={reportData.acceptanceDate}>{reportData.acceptanceDate}</ListItemText>
                                }
                            </ListItem>
                        </Grid>
                    </Grid>

                    <Divider style={{ width: '98%', margin: '0 auto' }} />

                    <Grid container md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={4} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography><span className="item-title">Acceptance Facility: </span></ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item md={8} lg={8} sm={8} xs={6}>
                            <ListItem>             
                                <ListItemText disableTypography key={reportData.acceptanceFacility}>{reportData.acceptanceFacility}</ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>

                    <Divider style={{ width: '98%', margin: '0 auto' }} />

                    <Grid container md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={4} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography><span className="item-title">WTF: </span></ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item md={8} lg={8} sm={8} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography key={reportData.wtf}>{reportData.wtf}</ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>

                    <Divider style={{ width: '98%', margin: '0 auto' }} />

                    <Grid container md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={4} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography><span className="item-title">Lorry Registration: </span></ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item md={8} lg={8} sm={8} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography key={reportData.lorryreg}>{reportData.lorryreg}</ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>

                    <Divider style={{ width: '98%', margin: '0 auto' }} />

                    <Grid container md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={4} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography><span className="item-title">Location: </span></ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item md={8} lg={8} sm={8} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography key={reportData.location}>{reportData.location}</ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>

                    <Divider style={{ width: '98%', margin: '0 auto' }} />

                    <Grid container md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={4} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography><span className="item-title">Invoice: </span></ListItemText>
                            </ListItem>
                        </Grid>
                        <Grid item md={8} lg={8} sm={8} xs={6}>
                            <ListItem>
                                <ListItemText disableTypography key={reportData.invoice}>{reportData.invoice}</ListItemText>
                            </ListItem>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <br />
            <Paper style={{ minWidth: 750 }}>
                {isLoading && <LinearProgress />}

                <div className="heading-h6">Waste Details </div>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className={classes.tableHead}>

                        <TableRow>
                            <TableCell align="center">Ewc</TableCell>
                            <TableCell className={classes.tableRightBorder} align="center">Proper Shipping Name</TableCell>
                            <TableCell className={classes.tableRightBorder} align="center">Type</TableCell>
                            <TableCell className={classes.tableRightBorder} align="center">Container</TableCell>
                            <TableCell className={classes.tableRightBorder} align="center">Number</TableCell>
                            <TableCell className={classes.tableRightBorder} align="center">Weight(kg)</TableCell>
                            <TableCell align="center">Overweight(kg</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(!data.items || data.items.length == 0) ?
                            <TableRow>
                                <TableCell colSpan={7}>
                                    {!isLoading &&
                                        <h3 style={{ textAlign: 'center' }}>No waste types added to report.</h3>
                                    }
                                </TableCell>
                            </TableRow>
                            : data.items.map((row) => (
                                <>
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >

                                        <TableCell align="right">{row.ewc}</TableCell>
                                        <TableCell align="right">{row.properShippingName}</TableCell>
                                        <TableCell align="right">{row.typeOf}</TableCell>
                                        <TableCell align="right">{row.container}</TableCell>
                                        <TableCell align="right">{row.number}</TableCell>
                                        <TableCell align="right">{row.weight}</TableCell>
                                        <TableCell style={{ color: "red" }} align="right">
                                            {(row.overweight > 0) ?
                                                <>+{(((row.weight / row.number) - row.maxWeight) * row.number).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}</>
                                                : ""}
                                        </TableCell>
                                    </TableRow>

                                    {(reportData.approved == true) &&

                                        <TableRow>
                                            <TableCell colSpan={7}>
                                                <Table aria-label="simple table" className={classes.tableNested}>
                                                    <TableHead className={classes.tableHeadNested}>
                                                        <TableRow className={classes.tableTitleRow}>
                                                            <TableCell colSpan={7} align="center" className={classes.subHeaderCell}>Treatments</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="center">Treatment Date</TableCell>
                                                            <TableCell className={classes.tableRightBorder} align="center">WTF</TableCell>
                                                            <TableCell className={classes.tableRightBorder} align="center">TFS</TableCell>
                                                            <TableCell className={classes.tableRightBorder} align="center">R/D Code</TableCell>
                                                            <TableCell className={classes.tableRightBorder} align="center">Facility</TableCell>
                                                            <TableCell align="right">Weight(kg)</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        
                                                        { row.wasteTreatment  && 

                                                            <TableRow
                                                                key={row.id}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >

                                                                <TableCell align="right">{row.wasteTreatment.sDate != null ? Moment(row.wasteTreatment.sDate).format('DD/MM/YYYY') : ""}</TableCell>
                                                                <TableCell align="right">{row.wasteTreatment.wtf}</TableCell>
                                                                <TableCell align="right">{row.wasteTreatment.tfS_No}</TableCell>
                                                                <TableCell align="right">{row.wasteTreatment.rdCode}</TableCell>
                                                                <TableCell align="right">{row.wasteTreatment.facility}</TableCell>
                                                                <TableCell align="right">{row.wasteTreatment.weight}</TableCell>
                                                            </TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableCell>
                                        </TableRow>
                                    }
                                    </>

                                    ////{(reportData.approved == false) &&

                                    ////    <TableRow>
                                    ////        <TableCell colSpan={7}>
                                    ////            <Table aria-label="simple table" className={classes.tableNested}>
                                    ////                <TableHead className={classes.tableHeadNested}>
                                    ////                    <TableRow className={classes.tableTitleRow}>
                                    ////                        <TableCell colSpan={7} align="center" className={classes.subHeaderCell}>Treatments</TableCell>
                                    ////                    </TableRow>
                                    ////                    <TableRow>
                                    ////                        <TableCell align="center">Treatment Date</TableCell>
                                    ////                        <TableCell className={classes.tableRightBorder} align="center">WTF</TableCell>
                                    ////                        <TableCell className={classes.tableRightBorder} align="center">TFS</TableCell>
                                    ////                        <TableCell className={classes.tableRightBorder} align="center">R/D Code</TableCell>
                                    ////                        <TableCell className={classes.tableRightBorder} align="center">Facility</TableCell>
                                    ////                        <TableCell align="right">Weight(kg)</TableCell>
                                    ////                    </TableRow>
                                    ////                </TableHead>
                                    ////                <TableBody>

                                    ////                    {(row.wasteTreatments && row.wasteTreatments.length > 0) && row.wasteTreatments.map((treatment: any) => (

                                    ////                        <TableRow
                                    ////                            key={row.id}
                                    ////                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    ////                        >

                                    ////                            <TableCell align="right">{treatment.sDate != null ? Moment(treatment.sDate).format('DD/MM/YYYY') : ""}</TableCell>
                                    ////                            <TableCell align="right">{treatment.wtf}</TableCell>
                                    ////                            <TableCell align="right">{treatment.tfS_No}</TableCell>
                                    ////                            <TableCell align="right">{treatment.rdCode}</TableCell>
                                    ////                            <TableCell align="right">{treatment.facility}</TableCell>
                                    ////                            <TableCell align="right">{treatment.weight}</TableCell>
                                    ////                        </TableRow>
                                    ////                    ))}
                                    ////                </TableBody>
                                    ////            </Table>
                                    ////        </TableCell>
                                    ////    </TableRow>
                                    ////}
                            ))}
                    </TableBody>
                </Table>
            </Paper>
            {reportData.overweight == true &&
                <ReportOverweightView id={params.id} />
            }

            {isNonConformance &&
                <ReportSurchargeView id={params.id} />
            }

            <div style={{ marginBottom: '20px' }}></div>
        </Container>
    );
}