import { Options } from 'material-table';
import { IFilteringViewModel } from '../_common/interface/iTableFilter.interface';
import { TableFilterEnum } from '../_common/enum/tableFilter.enum';
import { FilterEnum } from '../_common/interface/table';

export function setTableFilter(filter: IFilteringViewModel, propName: TableFilterEnum, propValue: any, onSet: (obj: IFilteringViewModel) => void, onSuccess?: () => void) {
    const query = { ...filter };
    query[propName.toString()] = propValue;
    if (propName.toString() === "limit")
        query.page = 0;
    if (onSet) {
        onSet({ ...query });
    }
    if (onSuccess) onSuccess();
}

export function setTableSort(filter: IFilteringViewModel, property: string, onSet: (obj: IFilteringViewModel) => void, onSuccess?: () => void) {
    const query = filter;
    query.orderBy = property;
    let order: 'asc' | 'desc' = 'desc';

    if (filter.orderBy === property && filter.order === 'desc') {
        order = 'asc';
    }

    query.order = order;

    if (onSet) onSet({ ...query });
    if (onSuccess) onSuccess();
}

export const getTableOptions = (limit: number): Options<any> => {

    return {
        search: true,
        toolbar: true,
        pageSize: limit,
        pageSizeOptions: [5, 10, 20, 50, 100],
        initialPage: 0,
        showEmptyDataSourceMessage: true,
        sorting: true,
        showTitle: true,
        loadingType: 'linear',
        debounceInterval: 200,
        emptyRowsWhenPaging: false,
        tableLayout: "auto",
        headerStyle: {
            fontStyle: "14px",
            backgroundColor: "#0000FF !important",
            color: 'yellow !important'
        },
        rowStyle: {
            overflowWrap: 'break-word',
            fontSize: "12px"
        }

    };
}

export const getTableOptionsNoSearch = (limit: number): Options<any> => {

    return {
        search: false,
        toolbar: false,
        pageSize: limit,
        pageSizeOptions: [5, 10, 20, 50, 100],
        initialPage: 0,
        showEmptyDataSourceMessage: true,
        sorting: true,
        showTitle: true,
        loadingType: 'linear',
        debounceInterval: 200,
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
        rowStyle: {
            overflowWrap: 'break-word'
        }
    };
}


export const getProjectDetailsTableOptions = (limit: number): Options<any> => {

    return {
        ...getTableOptions(limit),
        minBodyHeight: 300,
        maxBodyHeight: 300,
        showTitle: true,
    };
}
