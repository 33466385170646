import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { ImageListItem } from '@material-ui/core';

const img = require('../_assets/images/dark-tire.png');

export const loginStyles = makeStyles(theme => ({

    appScreen: {
        backgroundImage: "url(" + img + ")",
    },
    appLogo: {
        width: "calc(100% - 250px)",
        margin: "10px auto",
        display: "block",
    },
    logoBox: {
        marginBottom: "30px",
    },
    loginBox: {
    },
    mainBackground: {
        paddingTop: "25px",
    },
    ieBox: {
        maxWidth: "650px",
        margin: "0px auto"
    }
}));