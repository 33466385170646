import * as React from 'react';
import { useState } from 'react';
import { IStandardAutocomplete } from '../../../_common/interface/autocomplete/iStandardAutocomplete.interface';
import { accountService } from '../../../_services/account.service';
import { companyService } from '../../../_services/company.service';
import { MultipleAutoComplete } from '../multiple.autocomplete';

interface ICompanyAutoComplete extends IStandardAutocomplete {
    multipleValues?: Array<any>;
}

export const CompanyAutoComplete = (params: ICompanyAutoComplete) => {

    return (
        
        <MultipleAutoComplete
            {...params}
            itemsLoader={companyService.getCompaniesDropdown}
            label={"Select Company"} />
    );
}