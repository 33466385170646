import { useEffect, useState } from 'react';
import { API_URL } from '../_common/const/config.const';
import { getHeader, getQuery } from '../_helpers';
import { handleFailResponse, handleResponse } from '../_helpers/handle-response';
import { accountService } from './account.service';

const API_NAME = "Companies";

//const [customerIds, setCustomerIds] = useState("");

//console.log("CUSTOMER ID:", customerIds);
//useEffect(() => {
//    //accountService.userCompanies();
////    setCustomerIds(accountService.currentUserValue.customerIds);
//}, []);

export const companyService = {
    getCompaniesDropdown
};

function getCompaniesDropdown() {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetCompaniesDropdown/?customerIds=${accountService.currentUserValue.customerIds}`, requestOptions).then(handleResponse);
}
