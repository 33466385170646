import { API_URL } from '../_common/const/config.const';
import { getHeader, getQuery } from '../_helpers';
import { handleFailResponse, handleResponse } from '../_helpers/handle-response';

const API_NAME = "user";

export const userService = {
    get,
    getAll,
};

function get(userId: string) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/get/?userId=${userId}`, requestOptions).then(handleResponse, handleFailResponse);
}

function getAll(query?: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getAll/?${getQuery(query)}`, requestOptions).then(handleResponse, handleFailResponse);
}
