import { accountService } from "../_services/account.service";

export function authHeader(): any {
    // return authorization header with jwt token
    const currentUser = accountService.currentUserValue;

    //return { Authorization: `Bearer ${currentUser.token}` };
    if (currentUser && currentUser.token) {
        return { Authorization: `Bearer ${currentUser.token}` };
    }

    return {};
}