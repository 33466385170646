import { accountService } from '../../_services/account.service';

export interface IPrimaryNavInterface {
    icon: string;
    name: string;
    mobileName?: string;
    url: string;
    onClick?: any;
    png?: string;
}

const PRIMARY_NAV: IPrimaryNavInterface[] = [
    { icon: 'dashboard', name: 'Dashboard', mobileName: 'Dashboard', url: 'Dashboard' },
    { icon: 'description', name: 'Jobs', url: 'Reports' },
];


const ADMIN_NAV: IPrimaryNavInterface[] = [
    { icon: 'account_box', name: 'Users', url: 'Accounts' }
];


const USER_NAV: IPrimaryNavInterface[] = [
    { icon: 'logout', name: 'Log Out', url: 'logout', onClick: accountService.logout },
];

export { PRIMARY_NAV, ADMIN_NAV, USER_NAV };

