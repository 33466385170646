import { API_URL } from '../_common/const/config.const';
import { getHeader, getQuery } from '../_helpers';
import { handleFailResponse, handleResponse } from '../_helpers/handle-response';

const API_NAME = "reports";

export const reportService = {
    get,
    getReports,
    getAll: getReports,
    getAllWastes,
    getReportGraphs,
    getOverweightWastes,
    getSurchargeWaste,
    getAllWastesAndTreatments,    
};

function get(id: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/get/?id=${id}`, requestOptions).then(handleResponse, handleFailResponse);
}

function getReports(query?: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getReports/?${getQuery(query)}`, requestOptions).then(handleResponse, handleFailResponse);
}

function getReportGraphs(query?: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getReportGraphs/?${getQuery(query)}`, requestOptions).then(handleResponse, handleFailResponse);
}

function getAllWastes(id: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getAllWastes/?id=${id}`, requestOptions).then(handleResponse, handleFailResponse);
}


function getOverweightWastes(id: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getOverweightWastes/?id=${id}`, requestOptions).then(handleResponse, handleFailResponse);
}

function getAllWastesAndTreatments(id: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getAllWastesAndTreatments2/?id=${id}`, requestOptions).then(handleResponse, handleFailResponse);
}

function getSurchargeWaste(id: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/getSurchargeWaste/?id=${id}`, requestOptions).then(handleResponse, handleFailResponse);
}


