import { API_URL } from '../_common/const/config.const';
import { toastr } from '../_components/toastr/toastr';
import { getHeader, getQuery } from '../_helpers';
import { handleFailResponse, handleResponse } from '../_helpers/handle-response';

const API_NAME = "reportLibrary";

export const reportLibraryService = {
    getAcceptance,
    getDestruction,
    getShippingSummary,
    err
};

function getAcceptance(query?: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL.split("/api")[0]}/${API_NAME}/getAcceptance/?${getQuery(query)}`, requestOptions).then(handleResponse, handleFailResponse);

}

function getDestruction(query?: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL.split("/api")[0]}/${API_NAME}/getDestruction/?${getQuery(query)}`, requestOptions).then(handleResponse, handleFailResponse);
}

function getShippingSummary(query?: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL.split("/api")[0]}/${API_NAME}/getShippingSummary/?${getQuery(query)}`, requestOptions).then(handleResponse, handleFailResponse);
}


function err(query?: any) {
    toastr.error("Invalid endpoint");
}


